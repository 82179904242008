import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showPrint: false,
        printURL: "",
        barcodeMode: false,
        account_id: null,
        taxes: [],
        flutterwave_key: "FLWPUBK-156ac1734b17652dfd246eaca2ae41cd-X",
        findStep: 1,
        settings: null,
        sale_enc_id: "",
        generalMessage: '',
        showGeneralMessage: false,
        cartSideNav: false,
        minNav: false,
        scanStarted: false,
        rules: {
            age: [
                val => val < 10 || `I don't believe you!`,
            ],
            tel: [val => (val || '').length === 10 || 'This field is required'],
            text: [val => (val || '').length > 0 || 'This field is required'],
            required: [val => !!val || 'This field is required'],
        },
        momo: {
            message: "",
            failed: false,
            progress: false
        },
        baseUrl: "https://webnext.builtaccounting.com",
        search: "",
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";

        },
        initializing: false,
        user: null,
        validationRules: {
            required: value => !!value || 'Required.',
            counter: value => value.length <= 20 || 'Max 20 characters',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
        }

    },
    mutations: {
        // eslint-disable-next-line no-unused-vars
        initApp(state) {
            state.initializing = true;
            window.axios.get("/api/auth/user")
                .then(res => {
                    state.user = res.data;
                    state.initializing = false;

                    if (state.user.has_business) {

                        window.axios.get("/api/pos/settings")
                            .then(res => {
                                if (res.data) {
                                    state.settings = res.data;

                                    if (res.data.taxes) {
                                        res.data.taxes.split(",").forEach(tax => {
                                            state.taxes.push(tax);
                                        });
                                    }

                                    if (res.data.account_id) {

                                        state.account_id = res.data.account_id;

                                    }
                                }

                            })
                    }

                })
                .catch(() => {
                    state.initializing = false;

                    this.state.user = null;


                })

        }
    },
    actions: {},
    modules: {}
})
